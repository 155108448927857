import { useEffectOnce } from "react-use";
import { openInvite } from "shared/client.telefunc";
import { usePageContext } from "src/hooks/usePageContext";
import { useQuery } from "src/hooks/useQuery";
import { withSuspense } from "src/utils/withSuspense";
import { InvalidPage } from "./invalid";
import { Welcome } from "./page";
import Cookies from "js-cookie";
import { hasCookies } from "src/utils/hasCookies";
import { navigate } from "vike/client/router";

export const Page = withSuspense(() => {
  const { urlParsed } = usePageContext();
  const inviteId = urlParsed.search.invite;
  if (!inviteId) {
    if (!hasCookies()) {
      navigate("/error");
    } else {
      navigate("/precall");
    }
    return null;
  }

  return <Invite inviteId={inviteId} />;
});

const Invite = ({ inviteId }: { inviteId: string }) => {
  const { data: invite } = useQuery(["invite", inviteId], () =>
    openInvite(inviteId)
  );

  if (!invite || !invite.valid) {
    return <InvalidPage />;
  }

  return (
    <Welcome
      onAccept={() => {
        Cookies.set("invite-id", inviteId, { path: "/" });
        Cookies.set("lk-token", invite.token, { path: "/" });
      }}
    />
  );
};
